/* Global styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Header styles */
header {
  background-color: #070707;
  color: #fff;
  padding: 1em;
  width: 100%;
}

header h1 {
  margin: 0;
  font-size: 2.5em;
  
}

/* Main styles */
main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1em;
}

section {
  margin: 3em 0;
  padding: 50PX;
  text-align: justify;
}

section:first-of-type {
  margin-top: 0;
  
}


section h2 {
  font-size: 2em;
  margin-bottom: 1em;
  color: #070707;
  
}
article {
  width: 70%;
  text-align:justify;
  font-size: 1.5rem;
}

article h2 {
  text-align:left;
  font-size: 2.5rem;
}


.about-item {
  display: flex;
  align-items: center;
  margin-top: 2em;
}

.about-text {
  margin-left: 2em;
  font-size: 1.5em;

}

.about-text p {
  font-size: 1.1em;
}

.about-item img {
  max-width: 40%;
  border-radius: 10px;
}

/* Footer styles */
footer {
  background-color: #070707;
  color: #fff;
  padding: 1em;
  text-align: center;
}

footer p {
  margin: 0;
}
