.footer-container {
  background-color:yellowgreen;
  color: #fff;
  padding: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
}

.footer-nav {
  flex: 1 1 5rem;
}

.footer-nav ul {
  list-style: none;
  display: flex;
  align-items: left;
}

.footer-nav li {
  margin: 0 0.5rem;
  ;
}

.footer-nav a {
  color: #fff;
  font-size: 1rem;
  flex-direction: column;
}

.footer-nav a:hover {
  color: #00a8ff;
  cursor: pointer;
}


.footer-partners {
  flex: 1 1 20rem;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-left: 30px;
  width: 30%;
}

.footer-partners h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.footer-partners-logos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  }
 
  .footer-partners-logos img {
    width: 50px;
    margin: 1rem;
    }
    

    .footer-contact {
      flex: 1 1 20rem;
      display: flex;
      flex-direction:column;
      align-items: left;
      width: 30%;
    }
    
    .footer-adress {
      color: #fff;
      font-size: 1rem;
    }

    
    .footer-social-media {
      flex: 1 1 20rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      }
      
      .footer-social-media h3 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
      }
      
      .footer-social-media-logos {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 30%;
      }
      
      .footer-social-media-logos a {
      color: #fff;
      font-size: 1.2rem;
      margin: 0 1rem;
      }
      
      .footer-social-media-logos a:hover {
      color: #00a8ff;
      cursor: pointer;
      }
      
      .footer-newsletter {
      flex: 1 1 20rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      }
      
      .footer-newsletter h3 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
      }
      
      .footer-newsletter-form {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      }
      
      .footer-newsletter-form input[type="email"] {
      padding: 0.5rem;
      font-size: 1rem;
      border: none;
      border-radius: 5px;
      margin-right: 1rem;
      }
      
      .footer-newsletter-form input[type="submit"] {
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border: none;
      border-radius: 5px;
      background-color: #00a8ff;
      color: #fff;
      cursor: pointer;
      }
      
      .footer-newsletter-form input[type="submit"]:hover {
      background-color: #fff;
      color: #00a8ff;
      }
