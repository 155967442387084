

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  height: 100vh;
  background: #4d4848;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 400px;
  padding: 20px;
  height: 85vh;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(5, 35, 206, 0.986);
  background: #2cfc59;
}s

.form-container label {
  font-size: 16px;
  margin-bottom: 8px;
  border-color: #01070f;
  border:#01070f;
  
}

.form-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  resize: none;
}

.form-container button {
  background: #0066ff;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-container button:hover {
  background-color: #0047b3;
}

textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  resize: none;
  height: 100px;
}

.prestation-contenaire select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  resize: none;

}