.faq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 50px 20px;
  }
  
  .faq-container h1 {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
  }
  
  .faq-section h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .faq-section p {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 40px;
    text-align: justify;
  }
  
  .faq-item {
    margin-bottom: 50px;
  }
  