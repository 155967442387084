.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
 
.homepage-container {
  margin-top: 100px;
  background-color: white;
}

.welcome-section h1 {
  text-align: center;
  margin: 20px 0;
  color: rgb(255, 94, 0);
}

.slideshow-container {
  position: relative;
  margin: 0 auto;
  height: 450px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.slideshow-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slideshow-image.active {
  opacity: 1;
}

.slideshow-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.company-presentation {
  text-align: center;
  margin: 50px 0;
}
.company-presentation ul {
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 20px;
  text-align: left;
  font-size: small;
  line-height: 30px;
  text-align: justify;
}

.company-presentation h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.company-presentation p {
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .slideshow-container {
    height: 300px;
  }

  .company-presentation h2 {
    font-size: 2rem;
  }

  .company-presentation p {
    font-size: 1rem;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  
}
/* Style pour le bouton de la page au survol de la souris */
.btn:hover {
  background-color: #0062cc;
}