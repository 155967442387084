.headerhead {
  background-color: yellowgreen;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: 'Roboto', sans-serif; /* Utilisation de la police Roboto */
  padding: 15px;
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.header-nav {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
}

.header-nav ul {
  display: flex;
  list-style: none;
  justify-content: center;
  margin: 0;
  padding: 0;
  flex-grow: 1;
}

.header-nav li {
  margin: 0 10px;
}

.header-nav a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.header-nav a:hover {
  color: gray;
  cursor: pointer;
}

.logo {
  height: 60px; /* Ajustez la taille du logo selon vos besoins */
  margin-right: 20px;
}

body {
  font-family: 'Roboto', sans-serif; /* Application de la police Roboto au body */
}
