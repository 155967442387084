/* styles pour la section portfolio */
.portfolio-header {
  padding: 80px;
  text-align: center;
}

.portfolio-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 80px 0;
  box-shadow: 0px 4px 20px rgba(216, 23, 241, 0.582);
  border-radius: 20px;
  width: 80%;
  margin-left: 10%;
}

.portfolio-content p {
  justify-content: justify;
  font-size: 20px;
  width: 90%;
  line-height: 30px;
  text-align: justify;
}

.portfolio-content img {
  width: 80%;
  height: 50%;
}

.portfolio-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 80px 0;
  box-shadow: 0px 4px 20px rgba(216, 23, 241, 0.582);
  border-radius: 20px;
  width: 80%;
  margin-left: 10%;
}

.portfolio-mobile img {
  flex-direction: column;
  align-items: center;
  width: 30;
  height: 30;
  margin-right: 10px;
}

.portfolio-description {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;
  max-width: 90%;
  overflow: hidden;
}

.portfolio-text {
  width: 80%;
  padding: 20px;
}


.portfolio-text h2 {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.portfolio-text p {
  font-size: 1.2rem;
  font-weight: 400px;
  margin-bottom: 30px;
}

.portfolio-text ul {
  font-size : 100px;
  margin-top: 20px;
  padding-left: 20px;
  }
  
  .portfolio-text ul li {
  margin-bottom: 10px;
  list-style: disc;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  }
  
  