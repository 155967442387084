.header {
    background-color: #f7f7f7;
    padding: 60px 0;
    display: flex;
    align-items: center;
  }
  
  .header-text {
    display: block;
    text-align: center;
    max-width: 100%;
    background-color: #b1eaf1;
    padding: 60px 0;
    font-size: 1.5rem;

      }
      
  .header h1 {
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 20px;
  }
  
  .header p {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0;
    margin: 80%;
  }
  
  
  .services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 80px 0;
  }
  
  .service-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    width: 100%;
    max-width: 90%;
    box-shadow: 0px 4px 20px rgba(216, 23, 241, 0.582);
    border-radius: 20px;
    overflow: hidden;
  }
  
  .service-item:hover {
    transform: translateY(-10px);
    transition: all 0.3s ease-in-out;
  }
  
  .service-item img {
    width: 40%;
    height: auto;
    object-fit: cover;
  }
  
  .service-text {
    width: 60%;
    padding: 40px;
  }
  
  .service-text h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .service-text p {
    font-size: 1.2rem;
    font-weight: 400px;
    margin-bottom: 30px;
  }
  
  .service-text ul {
    font-size : 100px;
    margin-top: 20px;
    padding-left: 20px;
    }
    
    .service-text ul li {
    margin-bottom: 10px;
    list-style: disc;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    }
    
    @media (max-width: 768px) {
    .service-item {
    flex-direction: column;
    }
    
    .service-item img {
    width: 100%;
    }
    
    .service-text {
    width: 100%;
    padding: 20px;
    }
    
    .header h1 {
    font-size: 2.5rem;
    }
    
    .header p {
    font-size: 1rem;
    }
    
    .service-text h2 {
    font-size: 2rem;
    }
    
    .service-text p {
    font-size: 1rem;
    }
    
    .service-text ul li {
    font-size: 1rem;
    }
    }